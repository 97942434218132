"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, addClass, removeClass, on, closest, findAll} from '@elements/dom-utils'
import debounce from 'debounce';


export function init() {
    onFind('.js-scroll-indicator', function (scrollIndicator) {
        createScrollIndicator(scrollIndicator);
    });
}

function createScrollIndicator(scrollIndicator) {
    let scrollArea = findIn('.js-scroll-indicator__scroll-area', scrollIndicator);
    let scrollIndicatorEl =  findIn('.js-scroll-indicator__indicator', scrollIndicator);

    if (!scrollArea) {
        console.warn(`.js-scroll-indicator is missing a child with the class '.js-scroll-indicator__scroll-area'`, scrollArea);
        return;
    }

    if (!scrollIndicatorEl) {
        console.warn(`.js-scroll-indicator is missing a child with the class '.js-scroll-indicator__indicator'`, scrollIndicatorEl);
        return;
    }

    // check if scrollable : scrollArea.scrollWidth > scrollArea.clientWidth
    setVisibility();

    // if the element is in a tab, set visibility when the tab is shown
    const tabPaneParent = closest('.tab-pane:not(.active)', scrollIndicator);
    if (tabPaneParent) {
        const targetId = "#" + tabPaneParent.getAttribute('id');
        const triggerElements = findAll(`[data-toggle=tab][href="${targetId}"]`);

        triggerElements.map(triggerElement => {
            on('shown.bs.tab', function () {
                setVisibility();
            }, triggerElement);
        })

    }

    function setVisibility() {
        // check if area is horizontally scrollable or scrolled to the max
        if (scrollArea.clientWidth + scrollArea.scrollLeft < scrollArea.scrollWidth) {
            addClass('is-visible', scrollIndicatorEl);
        } else {
            removeClass('is-visible', scrollIndicatorEl);
        }
    }

    let lastScrollPosition = scrollArea.scrollLeft;
    function interval() {
        if (lastScrollPosition === scrollArea.scrollLeft) {
            requestAnimationFrame(interval);
            return;
        }

        lastScrollPosition = scrollArea.scrollLeft;
        setVisibility();

        requestAnimationFrame(interval);
    }

    requestAnimationFrame(interval);

    on('resize', debounce(() => {
        lastScrollPosition = Number.NEGATIVE_INFINITY;
    }, 200), window);

}
