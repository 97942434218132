"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, on, findAllIn, findAll} from "@elements/dom-utils";

export function init () {
    if (matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    onFind('.js-custom-cursor', function (cursor) {
        document.addEventListener("mousemove", e => {
            cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
        });

        document.addEventListener('mousedown', e => {
            addClass('is-active', cursor);
        });
        document.addEventListener('mouseup', e => {
            removeClass('is-active', cursor);
        });

        let interactiveElements = ['.js-drag', 'a:link', 'button', 'label', 'select']
            .map(x => x + ':not([disabled])');

        interactiveElements.forEach(function(interactiveElement) {
            onFind(interactiveElement, function(element) {
                on('mouseleave', () => {
                    removeClass('is-hover', cursor);
                }, element);

                on('mouseenter', () => {
                    addClass('is-hover', cursor);
                }, element);
            })
        });


       removeClass('is-hover', cursor);
       removeClass('is-active', cursor);

    });
}