import {initInScope,onFind} from "@elements/init-modules-in-scope";
import {addClass,removeClass,on} from '@elements/dom-utils';


// todo error handling
// todo fallback for not supported IEs

let elements = [];
window.elements = elements;


export function init() {
    if (!supportsTemplates()) {
        return;
    }

    onFind('.js-matchmedia-viewer', function (matchmediaElement) {

        addElements(matchmediaElement);

        // todo debounce
        const resizeHandler = evt => {
            processElements(elements);
        };

        on('resize', resizeHandler, window);

    });
}

function addElements(newElement) {

    let newElements = [newElement]
        .filter(element => !element.getAttribute('data-matchmedia-viewer-is-added'))
        .map((element) => {
            let container = element;
            let elementTag = element.tagName;
            let isTemplate = false;

            if (elementTag === 'TEMPLATE') {
                isTemplate = true;
                container = document.createElement('div');
                element.after(container);
            }

            element.setAttribute('data-matchmedia-viewer-is-added', true);

            return {
                content: isTemplate ? document.createElement('div').innerHTML = element.innerHTML : element.innerHTML,
                $container: container,
                media: element.getAttribute('data-matchmedia'),
                isCurrentlyEmbedded: !isTemplate
            };
        });


    processElements(newElements);
    elements = elements.concat(newElements);
}

function processElements(elements) {
    elements.forEach(function (element) {
        if (matchMedia(element.media).matches) {
            if (!element.isCurrentlyEmbedded) {
                element.isCurrentlyEmbedded = true;
                element.$container.innerHTML = "";
                element.$container.innerHTML = element.content;
                show(element.$container);
                initInScope(element.$container);

            }
        } else {
            if (element.isCurrentlyEmbedded) {
                element.isCurrentlyEmbedded = false;
                element.$container.innerHTML = "";
                hide(element.$container)
            }
        }
    });
}


function hide(element) {
    // element.setAttribute('hidden', 'hidden');
    // addClass('hidden', element)
    let newTemplate = document.createElement('template');
    newTemplate.innerHTML = element;
}

function show(element) {
    element.removeAttribute('hidden');
    removeClass('hidden', element)
}

function supportsTemplates() {
    return 'content' in document.createElement('template');
}