import {createElementsByHTML, findAllIn, findIn, matches, on} from "@elements/dom-utils";
import Modal from "bootstrap.native/dist/components/modal-native.esm";
import {initInScope} from "@elements/init-modules-in-scope";
import {init as baseInit} from "@elements/cookie-permissions"
import {getSelectionHTML} from "@elements/cookie-permissions/utils";

const modalOps = {backdrop: 'static', keyboard: false};
let _config = window['_config'] || {};
_config.cookieServices || (_config.cookieServices = {});

let cookieBarElement;
let cookieBarModal;
let cookieBarElementInitialized;

export function showCookiesInfo({
                                    uiServicePermissions,
                                    onAcceptAll,
                                    onAcceptNecessary,
                                    onDecline,
                                    onPermissionUpdate,
                                }) {

    cookieBarElement = document.getElementById('cookiePermissionModal');
    if (cookieBarElement) {
        cookieBarModal = new Modal(cookieBarElement, modalOps);
        cookieBarModal.show();
    } else {
        return;
    }

    if (cookieBarElementInitialized) {
        return cookieBarElement;
    }

    findAllIn('.js-cookie-bar__accept', cookieBarElement).map(on('click', onAcceptAll));

    findAllIn('.js-cookie-bar__detail', cookieBarElement).map(on('click', () => showCookiesSettings({
        uiServicePermissions,
        onPermissionUpdate
    })));

    cookieBarElementInitialized = true;
    return cookieBarElement;
}

export function hideCookiesInfo() {
    cookieBarModal.hide();
}

// modal
let permissionModalElement;
let permissionModal;

function createPermissionModal({
                                   uiServicePermissions,
                                   onPermissionUpdate
                               }) {
    permissionModalElement = createElementsByHTML(`
        <div class="cookie-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="cookie-modal-title">
            <div class="modal-dialog modal-dialog-centered cookie-modal__dialog">
                <div class="modal-content cookie-modal__content position-relative">
                    <div class="modal-header cookie-modal__header">
                        <h3 class="modal-title cookie-modal__title" id="cookie-modal-title">${_config.cookieModal.title}</h3>
                            <button type="button" class="close cookie-modal__close js-cookie-modal__show-overview" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" class="icon icon-close"></span>
                            </button>
                    </div>
                    <div class="modal-body cookie-modal__body">
                        ${getSelectionHTML({services: uiServicePermissions})}
                    </div>
                    <div class="modal-footer cookie-modal__footer"> 
                        <button type="button" class="btn btn-primary js-cookie-modal__save">${_config.cookieModal.save}</button>
                    </div>
                </div>
            </div>
        </div>
    `)[0];

    let globalToggle = findIn('input[name="all"]', permissionModalElement);
    let groupToggles = findAllIn('input[name^="group-"]', permissionModalElement);
    let serviceToggles = findAllIn('input:not([name="all"]):not([name^="group-"])', permissionModalElement);

    if (globalToggle) {
        on('change', () => {
            serviceToggles.filter(isNotDisabled).map(toggle => toggle.checked = !!globalToggle.checked);

            groupToggleStateUpdate();
        }, globalToggle);
    }

    groupToggles.map(on('change', (evt) => {
        let groupToggle = evt.target;
        let toggles = serviceToggles.filter(matches('[data-group="' + groupToggle.dataset.group + '"]'));
        toggles.filter(isNotDisabled).forEach(toggle => toggle.checked = !!matches(':checked', groupToggle));

        globalToggleStateUpdate();
    }));

    serviceToggles.map(on('change', () => {
        groupToggleStateUpdate();
        globalToggleStateUpdate();
    }));

    globalToggleStateUpdate();
    groupToggleStateUpdate();

    function globalToggleStateUpdate() {
        if (globalToggle) {
            globalToggle.checked = serviceToggles.some(matches(':checked'));
        }
    }

    function groupToggleStateUpdate() {
        groupToggles.forEach(function (groupToggle) {
            groupToggle.checked = serviceToggles.some(matches('[data-group="' + groupToggle.dataset.group + '"]:checked'));
        });
    }

    findAllIn('.js-cookie-modal__save', permissionModalElement).map(on('click', function () {
        onPermissionUpdate(serviceToggles.map(input => ({
            name: input.name,
            isAllowed: input.checked
        })));

        hideDetail();
    }));

    findAllIn('.js-cookie-modal__show-overview', permissionModalElement).map(on('click', function () {
        cookieBarModal.show();
    }));

    document.body.appendChild(permissionModalElement);
    initInScope(permissionModalElement);

    return permissionModalElement;
}

export function showCookiesSettings(params) {
    if (permissionModalElement) {
        permissionModalElement.remove();
    }

    permissionModalElement = createPermissionModal(params);

    permissionModal = new Modal(permissionModalElement, modalOps);
    permissionModal.show();
}

function hideDetail() {
    if (permissionModal) {
        permissionModal.hide();
    }
}

export const init = params => baseInit({
    showCookiesInfo,
    hideCookiesInfo,
    showCookiesSettings,
    ...params
});

// helper
const isNotDisabled = matches(':not([disabled])');