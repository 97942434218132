"use strict";

import {find, findAll, addClass, removeClass, on, off, hasClass, closest} from '@elements/dom-utils';

let navItems = findAll('.js-nav__item');
let navbarParent = find('.js-nav-parent');
let navbarMain = find('.navbar-main');

export function init() {

    if(matchMedia('(min-width: 768px)').matches){
        desktopNav();
    }else{
        mobileNav();
    }
}

function desktopNav() {

    let timeout = 200;
    let timer;
    navItems.forEach((navItem) => {
        navItem.addEventListener("mouseenter", function( event ) {
            clearTimeout(timer);
            timer = setTimeout(function() {
               openSubnav(navItem);
            }, timeout);

        }, false);

        navItem.addEventListener("mouseleave", function( event ) {
            clearTimeout(timer);
            timer = setTimeout(function() {
                closeSubnav();
            }, timeout);
        }, false);

        // touch
        navItem.addEventListener("touchend", function( evt ) {
            if (window.TouchEvent && evt instanceof TouchEvent) {
                if (!closest('.subnav', evt.target)) {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();

                    if (!hasClass('is-open', navItem)) {
                        openSubnav(navItem);
                        addBodyClickHandler(bodyClickHandler);
                    } else {
                        closeSubnav();
                        removeBodyClickHandler(bodyClickHandler);
                    }
                }

            }
        }, false);

        function bodyClickHandler(evt) {
            if (!closest('.navbar-main', evt.target)) {
                evt.preventDefault();
                closeSubnav();
                removeBodyClickHandler(bodyClickHandler);
            }
        }

    });

}

function mobileNav() {

    let navLinks = findAll('.js-nav__link');

    const navLinkClickhandler = (evt, navLink) => {
        evt.preventDefault();

        let navItem = closest('.js-nav__item', navLink);

        if(!hasClass('is-open', navItem)){
            openSubnav(navItem);
        }else{
            closeSubnav();
        }
    };

    navLinks.forEach((navLink) => {
        on('click', (evt) => navLinkClickhandler(evt, navLink), navLink);
    });
}

function openSubnav(navItem) {
    addClass('is-open', navbarMain);
    addClass('is-open',navbarParent);
    navItems.forEach((navItem) => {
        removeClass('is-open', navItem);
    });
    addClass('is-open',navItem);

}

function closeSubnav(){
    removeClass('is-open', navbarMain);
    removeClass('is-open', navbarParent);
    navItems.forEach((navItem) => {
        removeClass('is-open', navItem);
    });
}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}
function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}
