'use strict'

import loadScript from "@elements/load-script";
import {find, findIn, closest, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

const apiKey = _config.reCaptchaKey;

export function init() {

    onFind('.js-recaptcha-response', function (recaptchaEl) {
        window.grecaptchaOnload = function () {
            initRecaptcha(recaptchaEl);
        }

        // render explicitly
        loadRecaptchaAPI().then(() => {
            // if recaptcha has already been initialized do this, else the onloadCallback will be used
            if (typeof window.grecaptcha.render === 'function') {
                initRecaptcha(recaptchaEl);
            }

        });

    });

}

function initRecaptcha(recaptchaEl) {
    let form = closest('form', recaptchaEl);
    let ajaxForm = closest('.js-ajax-form', recaptchaEl);
    const submitBtn = findIn('button[type="submit"]', form);

    // add placeholder element for recaptcha after the input (can't be an input and it would be more effort to change the form template in symfony)
    let placeholderEL = document.createElement("div");
    recaptchaEl.after(placeholderEL);

    let widget = grecaptcha.render(placeholderEL, {
        'sitekey': apiKey,
        'callback': reCaptchaVerify,
        'size': 'invisible'
    });

    on('click', function (evt) {

        if (form.checkValidity()) {
            evt.preventDefault();
            grecaptcha.execute(widget);
        }
    }, submitBtn)

    function reCaptchaVerify(token) {
        // put recaptcha response in the input that comes from form builder
        recaptchaEl.value = token;
        // dispatch event, so eventHandlers (e.g. in ajax-form) are used (cancelable true, so ajaxform can cancel the native submit-event in FF)
        form.dispatchEvent(new Event('submit', { cancelable: true }));
    }

    if (ajaxForm) {
        on('failed.ajax-form', function() {
            grecaptcha.reset(widget);
        }, ajaxForm);
    }


}

export function loadRecaptchaAPI() {
    return loadScript('https://www.google.com/recaptcha/api.js?onload=grecaptchaOnload&render=explicit&hl=' + _config.lang);
}
