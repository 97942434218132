"use strict";

import {findIn, findAllIn, addClass, on, removeClass, removeAttribute, setAttribute} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-scrolling-area', function (container) {
        let prevButton = findAllIn('.js-scrolling-area__prev', container),
            nextButton = findAllIn('.js-scrolling-area__next', container),
            dots = findAllIn('.js-scrolling-area__dot', container),
            scrollArea = findIn('.js-scrolling-area__area', container);


        let items = findAllIn('.js-scrolling-area__item', container);

        if (!items.length) {
            console.warn(`.js-scrolling-area does not contain any children with the class '.js-scrolling-area__item'`, container);
            return;
        }

        const scrollWidth = getScrollWidth(items[0]);
        const offset = items[0].getBoundingClientRect().left;

        //initial scroll position 0
        scrollArea.scrollLeft = 0;

        nextButton.map((button) => {
            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth)
            }, button);
        });

        prevButton.map((button) => {
            if(scrollArea.scrollLeft === 0) {
                setAttribute('disabled', true, button);
            } else {
                removeAttribute('disabled', button);
            }

            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth)
            }, button);
        });

        dots.map((button) => {
            on('click', function () {
                let id = button.dataset.scrollingAreaId;
                findIn('.js-scrolling-area__item[data-scrolling-area-id="'+ id +'"]', container).scrollIntoView();
            }, button);
        });

        let isScrolling;
        scrollArea.addEventListener('scroll', function (evt) {
            window.clearTimeout(isScrolling);

            isScrolling = setTimeout(function() {
                dots.map((item) => removeClass('is-active', item));
                nextButton.map((button) => removeAttribute('disabled', button));

                if(scrollArea.scrollLeft === 0) {
                    prevButton.map((button) => setAttribute('disabled', true, button));
                } else {
                    prevButton.map((button) => removeAttribute('disabled', button));
                }

                items.map((item, index) => {
                    if (item.getBoundingClientRect().left <= scrollWidth && item.getBoundingClientRect().left >= offset) {
                        let id = item.dataset.scrollingAreaId;
                        let dot = findIn('.js-scrolling-area__dot[data-scrolling-area-id="'+ id +'"]', container);
                        if (dot) {
                            addClass('is-active', dot);
                        }

                        if (items.slice(-1)[0] === item) {
                            nextButton.map((button) => setAttribute('disabled', true, button));
                        }
                    }
                });

            }, 66);

        }, false);

        updateScrollWidth(items[0]);
    });
}

function scrollTo(scrollArea, scroll) {
    try {
        scrollArea.scroll({
            left: scroll,
            behavior: 'smooth'
        });
    } catch (e) {
        scrollArea.scrollLeft = scroll;
    }
}

function getScrollWidth(item) {
    return item.getBoundingClientRect().width;
}

function updateScrollWidth(item) {
    let delay = 250,
        throttled = false;

    window.addEventListener('resize', function() {
        if (!throttled) {
            getScrollWidth(item);
            throttled = true;
            setTimeout(function() {
                throttled = false;
            }, delay);
        }
    });
}
