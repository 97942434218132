"use strict";
import {on, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {init as initAjaxForm} from '@elements/ajax-form';


export function init () {
    window.dataLayer = window['dataLayer'] || [];

    onFind('.js-ajax-form', function (ajaxForm) {
        let formEl = findIn('.js-ajax-form__form', ajaxForm);
        let trackingEvent = formEl.getAttribute('data-tracking-event');

        if (trackingEvent) {
            on('submit.ajax-form',
                function () {
                    let dataTrackingAttrs = {};

                    let formAttributes = formEl.attributes;
                    for(let i = formAttributes.length - 1; i >= 0; i--) {
                        if (formAttributes[i].name.startsWith('data-tracking')) {
                            dataTrackingAttrs = {
                                ...dataTrackingAttrs,
                                [formAttributes[i].name]: formAttributes[i].value
                            }
                        }
                    }

                    window.dataLayer.push({
                        'event': 'form_' + trackingEvent,
                        ...dataTrackingAttrs
                    });

                    window.dataLayer.push({
                        'event': 'form_submission',
                        ...dataTrackingAttrs
                    });
                }, ajaxForm)
        }

        initAjaxForm();

    });
}
