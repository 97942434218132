import debounce from 'debounce';
import {addClass, closest, hasClass, removeClass, trigger, findAll} from "@elements/dom-utils";
import {onFind, onInitInScope} from "@elements/init-modules-in-scope";

let elements = [];
let currentElements = []; // all elements except for elements that are taller than the viewport
let matchesMediaQuery = true;
let lastScrollPosition = 0;
let isRunning = false;
let anchorNavItems = [];
const scrollThreshold = 30; //delta to scroll in px

const defaultOptions = {
    mediaQuery: null
};

const defaultSelectors = {
    base: '.js-affix-navbar',
    placeholder: '.js-affix-navbar__placeholder',
    addToPlaceholderHeightMd: '.js-affix-navbar__add-to-placeholder-md',
};

export const EVENTS = {
    AFFIX: 'affix/affix',
    DETACH: 'affix/detach'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    matchesMediaQuery = options.mediaQuery ? matchMedia(options.mediaQuery).matches : true;

    options = {
        ...defaultOptions,
        ...options
    };

    if (options.mediaQuery) {
        // Use addListener instead of addEventListener because of IE
        matchMedia(options.mediaQuery).addListener(({matches}) => {
            matchesMediaQuery = matches;

            if (matches) {
                if (isRunning) {
                    requestAnimationFrame(interval)
                }
            } else {
                elements.forEach(resetElement);
            }
        });
    }

    window.addEventListener('resize', debounce(() => {
        currentElements = elements;
        elements.forEach(resetElement);

        // if (matchesMediaQuery) {
        //     elements.forEach(obj => {
        //         let elementRect = obj.element.getBoundingClientRect();
        //         let placeholderRect = obj.placeholder.getBoundingClientRect();
        //
        //
        //         // if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
        //         //     if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
        //         //         setAffixBottom(obj);
        //         //     } else {
        //         //         setAffix(obj);
        //         //     }
        //         //
        //         //     obj.isAffix = true;
        //         // }
        //     });
        // }

        lastScrollPosition = Number.NEGATIVE_INFINITY;
        interval();
    }, 200));


    onFind(selectors.base, function (baseElement) {
        createAffix(
            baseElement,
            {options},
            {...defaultSelectors, ...selectors}
        );
    });

    onInitInScope(forceRecalculation);
}

export function createAffix(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    if (!matchesMediaQuery) {
        return;
    }
    let placeholder = closest(selectors.placeholder, baseElement);

    // baseElement.style.transform = 'translateZ(0)';

    if (!placeholder) {
        console.warn('Could not find parent ".js-affix__placeholder" for element ', baseElement);
        return null;
    }

    if (placeholder) {
        let addHeight = 0;
        if (matchMedia('(min-width: 768px)').matches) {
            let addHeightEls = findAll(selectors.addToPlaceholderHeightMd);

            addHeightEls.forEach(el => addHeight += el.getBoundingClientRect().height);
        }
        placeholder.style.minHeight = (baseElement.getBoundingClientRect().height + addHeight).toString() + "px";
    }

    let elementObj = {
        element: baseElement,
        placeholder: placeholder,
        isAffix: false,
        offset: +baseElement.getAttribute('data-affix-offset') || 0
    };

    elements = elements.concat(elementObj);
    // check if elements are in dom (so IE doesn't throw an error)
    elements = elements.filter(el => isElementInDom(el.element));

    currentElements = elements;

    anchorNavItems = document.querySelectorAll('.js-anchor-nav__item');

    lastScrollPosition = 0;
    setAffixStates();

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

export function forceRecalculation(){
    lastScrollPosition = Number.NEGATIVE_INFINITY;

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    if (!matchesMediaQuery) {
        return;
    }

    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    if (window.pageYOffset > 0) {
        currentElements.forEach(function (obj) {
            addClass('is-affix', obj.element);
            addClass('is-affix', obj.placeholder);
        });
    }

    if (anchorNavItems.length >= 2 && anchorNavItems[1].getBoundingClientRect().top < 60 ) {
        currentElements.forEach(function (obj) {
            addClass('show-anchornav', obj.element);
            addClass('show-anchornav', obj.placeholder);
        });
    } else {
        currentElements.forEach(function (obj) {
            removeClass('show-anchornav', obj.element);
            removeClass('show-anchornav', obj.placeholder);
        });
    }

    if (window.pageYOffset >= 100) {
        if (lastScrollPosition < window.pageYOffset) {
            // scrolled down
            currentElements.forEach(function (obj) {
                removeClass('scrolled-up', obj.element);
                removeClass('scrolled-up', obj.placeholder);

                addClass('scrolled-down', obj.element);
                addClass('scrolled-down', obj.placeholder);

            });

        } else if (lastScrollPosition > window.pageYOffset) {
            // scrolled up
            currentElements.forEach(function (obj) {
                addClass('scrolled-up', obj.element);
                addClass('scrolled-up', obj.placeholder);

                removeClass('scrolled-down', obj.element);
                removeClass('scrolled-down', obj.placeholder);
            });
        }
        currentElements.forEach(function (obj) {
            addClass('show-anchornav', obj.element);
            addClass('show-anchornav', obj.placeholder);
        });
    } else {
        currentElements.forEach(function (obj) {
        removeClass('show-anchornav', obj.element);
        removeClass('show-anchornav', obj.placeholder);
        });
    }

    lastScrollPosition = window.pageYOffset;



    requestAnimationFrame(interval);
}

function resetElement(obj) {
    removeClass('is-affix', obj.element);
    trigger(EVENTS.DETACH, obj.element);

    Object.assign(obj.element.style, {
        position: '',
        top: '',
        left: '',
        width: '',
        // transform: 'translateZ(0)',
    });

    if (obj.placeholder) {
        obj.placeholder.style.minHeight = obj.element.getBoundingClientRect().height.toString();
    }

    obj.isAffix = false;
}

function setAffixStates() {
    currentElements.forEach(function (obj) {
        // let elementRect = obj.element.getBoundingClientRect();
        // let placeholderRect = obj.placeholder.getBoundingClientRect();
        // let containerRect = obj.container.getBoundingClientRect();
        //
        // if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
        //     setAffixBottom(obj);
        //     obj.isAffix = false;
        // } else if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
        //     setAffix(obj);
        //     obj.isAffix = true;
        // } else {
        //     resetElement(obj);
        //     obj.isAffix = false;
        // }
    });
}

function isElementInDom(el) {
    return document.body.contains(el);
}
