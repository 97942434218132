"use strict";

import {find, addClass, removeClass, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

const CLASSES = {
    btnActiveClass: 'active'
};

export function init () {

    onFind('.js-toggle-element', function (button) {
        const btnClickHandler = (evt, btn) => {
            evt.preventDefault();
            let toggleElementSelector = btn.dataset.toggleElement;
            let toggleElement = find(toggleElementSelector);

            if (toggleElement) {
                if (toggleElement.hidden) {
                    toggleElement.hidden = false;
                    addClass(CLASSES.btnActiveClass, btn);
                } else {
                    toggleElement.hidden = true;
                    removeClass(CLASSES.btnActiveClass, btn);
                }

            }
        };
        on('click', (evt) => btnClickHandler(evt, button), button);
    });

}
