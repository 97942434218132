import { init as initCookieBar } from "./custom-cookie-permissions";
if (!_config.editmode && !_config.cookieBar.disable) {
    initCookieBar();
}

import * as toc from '@elements/toc';
toc.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as navigation from './navigation';
navigation.init();

import * as affixNavbar from './affix-navbar';
affixNavbar.init();

// import * as menuAim from './menu-aim';
// menuAim.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as formValidation from './formValidation';
formValidation.init();

import * as ajaxForm from './ajax-form';
ajaxForm.init();

import * as recaptcha from './reCaptchaV2';
recaptcha.init();

import * as fetchModal from './fetch-modal';
fetchModal.init();

import * as customCursor from './custom-cursor';
customCursor.init();

import * as sharingPopup from './sharing-popup';
sharingPopup.init();


