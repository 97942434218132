import './scripts/common';

import {onFind} from "@elements/init-modules-in-scope";
import {find} from '@elements/dom-utils'

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import Modal from 'bootstrap.native/dist/components/modal-native.esm';
onFind('[data-toggle="modal"]',function (element) {
    let modal = find( element.getAttribute('data-target') || element.getAttribute('href') );
    if (modal) {
        new Modal(element);
    }
});


if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/


import * as dragScrolling from './scripts/drag-scrolling';
dragScrolling.init();



import * as matchMediaViewer from './scripts/matchmedia-viewer';
matchMediaViewer.init();

import * as showAllContent from './scripts/show-all-content';
showAllContent.init();

import * as anchorNav from '@elements/anchor-nav';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="list-inline-item anchornav__item"><a class="js-smoothscroll__link" href="#' + anchor + '">' + text + '</a></li>';
        },
        smoothScroll: false,
        scrollSpy: true,
        scrollSpyOffset: matchMedia('(min-width: 768px)').matches ? 144 : 120,
        scrollSpyTarget: '.js-anchor-nav'
    },
    {
        list: '.js-anchor-nav',
        listItem: '.js-anchor-nav__item'
    }
);

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({scrollOffset: matchMedia('(min-width: 768px)').matches ? 144 : 100});

import * as anchorNavExtension from './scripts/anchornav-extension';
anchorNavExtension.init();

import * as toggleElement from './scripts/toggle-element';
toggleElement.init();

import * as affix from '@elements/affix';
affix.init();

import * as loadMore from '@elements/load-more';
loadMore.init();

import * as scrollIndicator from './scripts/scroll-indicator';
scrollIndicator.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();

import * as scrollingArea from './scripts/scrolling-area';
scrollingArea.init();
