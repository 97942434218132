"use strict";

import {on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-sharing-popup', function (button) {
        const btnClickHandler = (evt, btn) => {
            evt.preventDefault();
            let url = btn.getAttribute('href');

            // if a twitter widget is embedded on current site twitter's widget.js
            // will open a popup so we should not open a second one.
            if (url.match(/twitter\.com\/intent\/(\w+)/)) {
                let w = global.window
                if (w.__twttr && w.__twttr.widgets && w.__twttr.widgets.loaded) {
                    return;
                }
            }

            global.window.open(url, '_blank', 'width=600,height=460')
        };
        on('click', (evt) => btnClickHandler(evt, button), button);
    });

}
