"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, findAllIn, on} from '@elements/dom-utils';

const selectors = {
    video: '.js-play-video__video',
    overlay: '.js-play-video__overlay',
    base: '.js-play-video'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        on('click', function () {
            let overlay = findIn(selectors.overlay, baseElement);
            let video = findIn(selectors.video, baseElement);
            video.play();
            overlay.hidden = true;
        }, baseElement);
    });
}
