"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass} from "@elements/dom-utils";


export function init() {
    onFind('.js-anchor-nav', function (anchorNav) {
        // click handlers
        let links = anchorNav.querySelectorAll('.js-smoothscroll__link');
        let prevLink = document.querySelector('.js-anchor-nav__prev');
        let nextLink = document.querySelector('.js-anchor-nav__next');

        let totalCountEl = document.querySelector('.js-anchor-nav__total');
        let currentNumberText = document.querySelector('.js-anchor-nav__current');

        if (totalCountEl) {
            totalCountEl.textContent  = links.length;
        }

        document.body.addEventListener('activate.bs.scrollspy', function (evt) {
            let activeLink = evt.relatedTarget;
            let activeLinkLi = activeLink.parentNode;
            let linksUl = activeLinkLi.parentNode;
            let activeLinkIndex = [...linksUl.children].indexOf(activeLinkLi);

            if (currentNumberText) {
                currentNumberText.textContent  = activeLinkIndex + 1;
            }

            //console.log('prev', linksUl.children[activeLinkIndex - 1].querySelector('a').getAttribute('href'));
            if (linksUl.children[activeLinkIndex - 1]) {
                let prevHref = linksUl.children[activeLinkIndex - 1].querySelector('a').getAttribute('href');
                prevLink.setAttribute('href', prevHref);
                removeClass('disabled', prevLink);
            } else {
                addClass('disabled', prevLink);
            }
            //console.log('activeLinkIndex', activeLinkIndex);

            //console.log('next', linksUl.children[activeLinkIndex + 1].querySelector('a').getAttribute('href'));
            if (linksUl.children[activeLinkIndex + 1]) {
                let nextHref = linksUl.children[activeLinkIndex + 1].querySelector('a').getAttribute('href');
                nextLink.setAttribute('href', nextHref);
                removeClass('disabled', nextLink);
            } else {
                addClass('disabled', nextLink);
            }

            if (matchMedia('min-width: 768px')) {
                // center link in scrollparent everytime it becomes active
                center(linksUl.parentElement, activeLinkLi);
            }
        })
    });
}

function center(scrollParent, activeLi) {
    if(activeLi) {
        let childPos = activeLi.offsetLeft, // position of Li in scrollparent
            offset = childPos -  scrollParent.offsetWidth / 2 + activeLi.getBoundingClientRect().width / 2;

        scrollParent.scroll({
            left: offset,
            behavior: "smooth"
        });
    }
}
