"use strict";

import {findIn, closest, toggleClass, addClass, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    if (!_config.editmode){
        onFind('.js-show-all-content__content', function (content) {
            let showAllContentWrapper = closest('.js-show-all-content', content);
            let maxHeight =  showAllContentWrapper.dataset.maxHeight || 85;

            if(content.offsetHeight > maxHeight){
                addClass('show-all-content__max-height',showAllContentWrapper);
                content.style.maxHeight = maxHeight + 'px';

                let showAllContentBtn =  findIn('.js-show-all-content__btn', showAllContentWrapper);
                showAllContentBtn.hidden = false;
            }else{
                let btn = findIn('.js-show-all-content__btn', showAllContentWrapper);

                if (btn) {
                    btn.hidden = true;
                }
            }
        });

        onFind('.js-show-all-content__btn', function (button) {
            const btnClickHandler = (evt, btn) => {
                let showAllContentWrapper = closest('.js-show-all-content', btn);

                toggleClass('show', showAllContentWrapper);
            };
            on('click', (evt) => btnClickHandler(evt, button), button);
        });
    }
}
