"use strict";
import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import Modal from 'bootstrap.native/dist/components/modal-native.esm';

export function init () {
    onFind('.js-ajax-modal-toggle', function (element) {
        let modalSelector = element.getAttribute('data-target');
        let modal = find(modalSelector);

        if (modal) {
            let modalInit = new Modal(modal);

            on('click', function () {
                let url = element.getAttribute('data-content-url');

                modalInit.show();

                asyncAppend({
                    target: findAllIn('.js-ajax-modal__content', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal)
                }, fetch(url));
            }, element);

            on('hidden.bs.modal', function () {
                empty(findIn('.js-ajax-modal__content', modal));
            }, modal);
        } else {
            console.warn(`.js-ajax-modal-toggle: "${modalSelector}" not found`)
        }

    });
}
